import React from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from "src/utils/context";

export default class PageTemplate extends React.Component {
  state = {
    story: {
      content: this.props.data.story
        ? {
            ...JSON.parse(this.props.data.story.content),
            first_published_at: this.props.data.story.first_published_at,
            full_slug: this.props.data.story.full_slug,
          }
        : {},
    },
  };

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);
    let {
      data: { story },
    } = await StoryblokService.get(
      `cdn/stories/${this.props.data.story.full_slug}`
    );
    return story;
  }

  async componentDidMount() {
    let story = await this.getInitialStory();
    if (story.content) this.setState({ story });
    setTimeout(() => StoryblokService.initEditor(this), 200);
  }

  render() {
    return (
      <PageContext.Provider
        value={{
          currentPage: this.state.story.uuid,
          breadcrumb: this.props.pageContext.breadcrumb,
          location: this.props.location,
          name: this.state.story.name,
          first_published_at: this.state.story.first_published_at,
          content: this.state.story.content,
        }}
      >
        <Layout location={this.props.location}>
          <Page blok={{...this.state.story.content, first_published_at: this.state.story.first_published_at, name: this.props.data.story.name ? this.props.data.story.name : this.state.story.name}} />
        </Layout>
      </PageContext.Provider>
    );
  }
}

export const query = graphql`
  query storyBySlug($fullSlug: String!) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      name
      content
      full_slug
      uuid
      first_published_at
    }
  }
`;
